import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import CustomerStoryTile from '../components/CustomerStoryTile'
import theme from '../theme'
import Block from './Block'

export const query = graphql`
  {
    miro: file(relativePath: { eq: "images/story/miro/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
    gorgias: file(relativePath: { eq: "images/story/gorgias/hero-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 343, layout: FULL_WIDTH)
      }
    }
  }
`

const CustomerTestimonials = () => {
  const data = useStaticQuery(query)
  const miroPhoto = data.miro.childImageSharp.gatsbyImageData
  const gorgiasPhoto = data.gorgias.childImageSharp.gatsbyImageData

  return (
    <Container>
      <Block type="padding-small" centered>
        <h6>Don’t just take our word for it</h6>
        <div className="testimonials">
          <CustomerStoryTile
            company="Miro"
            text="delivers relevant insights to every level of the engineering organization"
            image={miroPhoto}
          />
          <CustomerStoryTile
            company="Gorgias"
            text="uses Swarmia to make better decisions and save hundreds of hours"
            image={gorgiasPhoto}
          />
        </div>
      </Block>
    </Container>
  )
}

export default CustomerTestimonials

const Container = styled.div`
  .testimonials {
    display: flex;
    justify-content: center;
    gap: 32px;

    @media screen and (max-width: ${theme.breakpoints.large}) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: ${theme.breakpoints.medium}) {
      gap: 0px;
    }
  }
`
